<template>
  <div v-loading="isLoading" id="comment-management-detail">
    <div class="content content-bg">
      <el-row type="flex" justify="end" align="middle" class="status-row">
        <span class="el-form-item__label text-bold">{{ $t("STATUS") }}:</span>
        <el-select
          v-model="detail.status"
          :class="getStatusClass(detail.status) + (isMobile ? ' w-100' : '')"
          :disabled="fullAccess ? !canChangeStatus(detail.status) : true"
        >
          <el-option
            v-for="item in allSelectableStatusListFor(detail.status)"
            :key="item.value"
            :label="$t(item.value)"
            :value="item.value"
          />
        </el-select>
      </el-row>

      <el-form
        ref="form"
        :model="detail"
        label-position="top"
        label-width="120px"
        disabled
      >
        <el-row
          :type="isMobile ? '' : 'flex'"
          justify="space-between"
          align="middle"
          :gutter="40"
        >
          <el-col :span="isMobile ? 24 : 8">
            <el-form-item :label="$t('DATE_TIME')">
              <el-input :value="parseDate(detail.createdDate)" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item :label="$t('MEMBER')">
              <el-input :value="getFullName(detail.user)" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          :type="isMobile ? '' : 'flex'"
          justify="space-between"
          align="middle"
          :gutter="40"
        >
          <el-col :span="isMobile ? 24 : 8">
            <el-form-item :label="$t('ARTICLE_LINK')">
              <div class="el-input el-input--medium is-disabled">
                <div class="el-input__inner">
                  <el-link
                    type="primary"
                    @click="goToArticle(detail.article, true)"
                  >
                    {{ $t("CLICK_TO_OPEN") }}
                  </el-link>
                  <i
                    class="custom-icon mini pointer icon-copy"
                    @click="copyArticleLink(detail.article)"
                  />
                </div>
              </div>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item :label="$t('ARTICLE_NAME')">
              <el-input :value="detail.article.title | translate" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-between" align="middle" :gutter="40">
          <el-col>
            <el-form-item :label="$t('COMMENT')">
              <el-input
                type="textarea"
                resize="none"
                :rows="7"
                :value="detail.text"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="content">
      <el-table v-loading="isLoading" class="newsily-bo-table" :data="list">
        <el-table-column :label="$t('DATE')" width="100">
          <template slot-scope="{ row }">
            {{
              row.createdDate != null
                ? moment(row.createdDate).format(DATE_FORMAT)
                : null
            }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('TIME')" width="100">
          <template slot-scope="{ row }">
            {{
              row.createdDate != null
                ? moment(row.createdDate).format(TIME_FORMAT)
                : null
            }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('REPLIED_COMMENT')"
          :width="isMobile ? 300 : null"
        >
          <template slot-scope="{ row }">
            {{ row.text }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('MEMBER')" :width="isMobile ? 120 : 150">
          <template slot-scope="{ row }">
            {{ getFullName(row.user) }}
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('STATUS')" width="120">
          <template slot-scope="{ row }">
            <div>
              <span :class="getStatusClass(row.status)">{{
                $t(row.status)
              }}</span>
              <el-dropdown class="action-button" trigger="click" :disabled="!isAdmin && !isManager">
                <i class="custom-icon icon-edit mini center-in-div pointer" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="row.status != 'ACTIVE'"
                    @click.native="changeRepliedStatus(row.id, 'ACTIVE')"
                  >
                    {{ $t("ACTIVE") }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="row.status != 'INACTIVE'"
                    @click.native="changeRepliedStatus(row.id, 'INACTIVE')"
                  >
                    {{ $t("INACTIVE") }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-loading="isLoading"
        v-show="total > 0"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        :total="total"
        @pagination="handleCurrentChange"
      />
    </div>

    <page-footer
      :isLoading="isLoading"
      :handleBackTo="exit"
      :handleSubmit="
        fullAccess && detail.status != 'ARCHIVED' ? changeStatus : null
      "
      :handleArchive="
        isAdmin && detail.status != 'ARCHIVED' ? handleArchive : null
      "
    />
  </div>
</template>


<script>
import { generalMixin } from "@/utils/general-mixin.js";
import { PAGE_SIZES } from "@/constants";
import PageFooter from "@/components/page-footer.vue";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "@/components/table/pagination.vue";
import moment from "moment";
import {
  getCommentById,
  archiveCommentById,
  changeStatus,
  getCommentList,
} from "@/api/comment";

const filterQuery = {
  page: 1,
  limit: PAGE_SIZES[0],
  articleId: null,
  parentCommentId: null,
  showSuspended: true,
};

export default {
  name: "CommentManagementDetail",
  components: { PageFooter, Pagination },
  mixins: [generalMixin],
  data() {
    return {
      moment,
      isLoading: false,
      detail: {
        createdDate: null,
        article: {
          id: null,
          name: [],
        },
        text: null,
      },
      listQuery: cloneDeep(filterQuery),
      total: 0,
      list: [],
    };
  },
  methods: {
    // REDIRECTION
    exit() {
      this.goTo("/bo/comment");
    },
    getDetail() {
      getCommentById(this.id)
        .then((res) => {
          if (res.status == "ok") {
            this.detail = res.result;
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.getRepliedComment();
        });
    },
    getRepliedComment() {
      // console.log("getRepliedComment", this.detail);
      this.listQuery.articleId = this.detail.article.id;

      getCommentList(this.listQuery)
        .then((res) => {
          if (res.status != "ok") return;
          this.list = cloneDeep(res.result.list);
          this.total = res.result.total;

          // Back to previous page if empty data page
          const page = res.result.page;
          const finalPage = res.result.finalPage;
          if (finalPage < page) {
            this.listQuery.page = finalPage;
            this.getRepliedComment();
          }
        })
        .catch(this.handleError);
    },
    async handleCurrentChange(val) {
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;

      this.isLoading = true;
      const promises = [this.getRepliedComment()];
      await Promise.all(promises);
      this.isLoading = false;
    },
    changeRepliedStatus(id, status) {
      this.isLoading = true;
      changeStatus({
        id: id,
        status: status,
      })
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("UPDATE_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION,
            });
          }
          this.getRepliedComment();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeStatus() {
      this.isLoading = true;
      changeStatus({
        id: this.id,
        status: this.detail.status,
      })
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("UPDATE_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION,
            });
          }
          this.exit();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleArchive() {
      this.isLoading = true;
      archiveCommentById(this.id)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("ARCHIVED_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION,
            });
          }
          this.exit();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async getPageDetails() {
      this.isLoading = true;
      const promises = [this.getDetail()];
      await Promise.all(promises);
      this.isLoading = false;
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.listQuery.parentCommentId = Number(this.$route.params.id);
    this.getPageDetails();
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@import "@/assets/style/mixin/detail.scss";
@import "@/assets/style/mixin/table.scss";

#comment-management-detail {
  @include detail-language-options;
  @include newsily-bo-table;

  .content {
    border-radius: 10px;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .content-bg {
    background-color: $white;
  }

  .status-row {
    margin: auto $spacingLeftRight;
    .el-input__prefix,
    .el-input__suffix {
      top: 0;
    }
  }
  .el-form {
    margin: auto $spacingLeftRight;
  }
  .icon-copy {
    margin-left: 1rem;
  }
  .el-link {
    margin-bottom: 10px;
  }
  .action-button {
    float: right;
  }
}

.mobile #comment-management-detail {
  .icon-copy {
    float: right;
    margin-top: 0.5rem;
  }
}
</style>